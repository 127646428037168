var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap booking_combine_detail",
      staticStyle: { width: "900px", height: "530px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("Booking Combine")]),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            _c("div", { staticClass: "flex_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.257"))),
              ]),
              _c("div", { staticClass: "bkbl_no tbl_topright" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.258"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "txt_bar2" }),
                _c("span", [_vm._v(_vm._s(_vm.bkgNo))]),
              ]),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.259")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("ul", { staticClass: "schedule_info" }, [
                      _c("li", [
                        _vm._v(_vm._s(_vm.objBookingDetailMain.porPlcEnm)),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.CSBK100.260")) +
                            " : " +
                            _vm._s(_vm.etd) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.030")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("ul", { staticClass: "schedule_info" }, [
                      _c("li", [
                        _vm._v(_vm._s(_vm.objBookingDetailMain.dlyPlcEnm)),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.CSBK100.261")) +
                            " : " +
                            _vm._s(_vm.eta) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.262")))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.objBookingDetailMain
                            ? _vm.objBookingDetailMain.vslNm +
                                "/" +
                                _vm.objBookingDetailMain.voyNo
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.011")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      attrs: {
                        type: "text",
                        id: "txtRealCompany",
                        name: "txtRealCompany",
                        maxlength: "30",
                        readOnly: "",
                      },
                      domProps: {
                        value: _vm.objBookingDetailMain.actShprCstEnm,
                      },
                    }),
                    _vm.lang == "KOR"
                      ? _c("span", { staticClass: "float_right" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              id: "consYn",
                              name: "consYn",
                              disabled: "",
                            },
                            domProps: {
                              checked: _vm.objBookingDetailMain.consYn == "Y",
                            },
                          }),
                          _c("label", { attrs: { for: "consYn" } }, [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.263"))),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.030-1")))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm.cstCatCd != "Y"
                      ? _c("div", { staticClass: "tbl_form" }, [
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.cboTerm,
                                    expression: "objBookingDetailMain.cboTerm",
                                  },
                                ],
                                attrs: {
                                  name: "cboTerm",
                                  id: "cboTerm",
                                  disabled: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnChangeType($event, "cboTerm")
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "cboTerm",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("CFS"),
                                ]),
                                _c("option", { attrs: { value: "03" } }, [
                                  _vm._v("CY&CFS"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.podShtmCd,
                                    expression:
                                      "objBookingDetailMain.podShtmCd",
                                  },
                                ],
                                attrs: {
                                  name: "podTerm",
                                  id: "podTerm",
                                  disabled: "",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "podShtmCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("CFS"),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "tbl_form" }, [
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.cboTerm,
                                    expression: "objBookingDetailMain.cboTerm",
                                  },
                                ],
                                attrs: { name: "cboTerm", id: "cboTerm" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "cboTerm",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("Tackle"),
                                ]),
                                _c("option", { attrs: { value: "03" } }, [
                                  _vm._v("FI"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.podShtmCd,
                                    expression:
                                      "objBookingDetailMain.podShtmCd",
                                  },
                                ],
                                attrs: { name: "podTerm", id: "podTerm" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "podShtmCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "05" } }, [
                                  _vm._v("Tackle"),
                                ]),
                                _c("option", { attrs: { value: "07" } }, [
                                  _vm._v("FO"),
                                ]),
                                _c("option", { attrs: { value: "09" } }, [
                                  _vm._v("T/S Zone"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.027-1")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.bookingDate))]),
                ]),
                _vm.lang == "JPN" && _vm.fwdrCstCd == "01"
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.264")))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.objBookingDetailMain.bkgShprCstNo,
                                  expression:
                                    "objBookingDetailMain.bkgShprCstNo",
                                },
                              ],
                              attrs: { name: "bkgShprCstNo" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.objBookingDetailMain,
                                    "bkgShprCstNo",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.050"))),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.bkgShprList, function (item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: {
                                      selected:
                                        item.bkgShprCstNo ==
                                        _vm.objBookingDetailMain.bkgShprCstNo,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.bkgShprCstEnm) + " "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v("     "),
                          _c("input", {
                            staticClass: "input txt",
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              name: "bkgShprCstEnm",
                              maxlength: "50",
                            },
                            domProps: {
                              value: _vm.objBookingDetailMain.bkgShprCstEnm,
                            },
                            on: {
                              blur: function ($event) {
                                this.value = this.value.toUpperCase()
                              },
                            },
                          }),
                          _c("br"),
                          _c("font", { attrs: { color: "red" } }, [
                            _vm._v(
                              "* 弊社と運賃のご契約をされた方を意味します。"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.lang == "JPN"
                  ? _c("tr", [
                      _c("th", [_vm._v("通関業者")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.objBookingDetailMain.fwdrCstNo,
                                expression: "objBookingDetailMain.fwdrCstNo",
                              },
                            ],
                            staticClass: "col_3",
                            attrs: { name: "fwderSelect", id: "fwderSelect" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.objBookingDetailMain,
                                  "fwdrCstNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.050"))),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fwdrList, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    selected:
                                      _vm.fwdrList.fwdrCstNo ==
                                      _vm.objBookingDetailMain.fwdrCstNo,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.fwdrCstEnm) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.objBookingDetailMain.fwdrCstEnm,
                              expression: "objBookingDetailMain.fwdrCstEnm",
                            },
                          ],
                          staticClass: "input txt inp_space50",
                          staticStyle: { width: "220px" },
                          attrs: {
                            type: "text",
                            id: "fwdrCstEnm",
                            maxlength: "50",
                            readOnly: _vm.cstYn,
                          },
                          domProps: {
                            value: _vm.objBookingDetailMain.fwdrCstEnm,
                          },
                          on: {
                            blur: function ($event) {
                              this.value = this.value.toUpperCase()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.objBookingDetailMain,
                                "fwdrCstEnm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.267")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.objBookingDetailMain.cmdtDsc },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.268")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "span",
                      { staticClass: "wg" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "combineGrsWtTon",
                            "is-comma": true,
                            "input-class": "wid80",
                            disabled: _vm.isKg,
                            point: 3,
                            maxlength: "30",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeGrsWt($event, "TON")
                            },
                          },
                          model: {
                            value: _vm.grsWtTon,
                            callback: function ($$v) {
                              _vm.grsWtTon = $$v
                            },
                            expression: "grsWtTon",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.269")) + " "),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "color_gray span_grs" }, [
                      _vm._v(" / "),
                    ]),
                    _c(
                      "span",
                      { staticClass: "wg" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "combineGrsWtKg",
                            "is-comma": true,
                            "input-class": "wid80",
                            disabled: _vm.isTon,
                            maxlength: "30",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeGrsWt($event, "KG")
                            },
                          },
                          model: {
                            value: _vm.grsWtKg,
                            callback: function ($$v) {
                              _vm.grsWtKg = $$v
                            },
                            expression: "grsWtKg",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.270")) + " "),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm.editView != "Y"
              ? [
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.325"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.combineMasterList, function (mast, index) {
                    return _c("table", { key: index, staticClass: "tbl_col" }, [
                      _vm._m(1, true),
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.287")))]),
                          _vm._v(" "),
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.258"))),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.326"))),
                          ]),
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.268"))),
                          ]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td"),
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(" " + _vm._s(mast.bkgNo) + " "),
                          ]),
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(" " + _vm._s(mast.actShprCstEnm) + " "),
                          ]),
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(" " + _vm._s(mast.grsWt) + " KG "),
                          ]),
                        ]),
                      ]),
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c("th"),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.274"))),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.275"))),
                            ]),
                            _vm._v(" "),
                            _vm.objBookingDetailMain.polShtmCd == "01"
                              ? [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.276"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.277"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.278"))),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm.objBookingDetailMain.polShtmCd == "02"
                              ? [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.279"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { colspan: "2" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.280"))),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(
                            _vm.combineCntrList,
                            function (cntr, cntrIndex) {
                              return _c(
                                "tr",
                                { key: cntrIndex },
                                [
                                  mast.bkgNo == cntr.bkgNo
                                    ? [
                                        cntr.cntrSeqRn == 1
                                          ? _c("td", [
                                              _c("input", {
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "check_" + (index + 1),
                                                  name: "check_" + (index + 1),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.combineContainerAttach(
                                                      $event.target.checked,
                                                      mast.bkgNo,
                                                      mast.appCnt
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "check_" + (index + 1),
                                                  },
                                                },
                                                [_c("span")]
                                              ),
                                            ])
                                          : _c("td", {
                                              staticStyle: {
                                                "border-top": "none",
                                              },
                                            }),
                                        _c("td", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: cntr.cntrSzCd,
                                                  expression: "cntr.cntrSzCd",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "cntrSzCd_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                id:
                                                  "cntrSzCd_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                disabled: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    cntr,
                                                    "cntrSzCd",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "20" } },
                                                [_vm._v("20")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "40" } },
                                                [_vm._v("40")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: cntr.cntrTypCd,
                                                  expression: "cntr.cntrTypCd",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "cntrTypCd_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                id:
                                                  "cntrTypCd_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                disabled: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    cntr,
                                                    "cntrTypCd",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "GP" } },
                                                [_vm._v("Dry")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "HC" } },
                                                [_vm._v("HIGH CUBE")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "RF" } },
                                                [_vm._v("REEFER")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "RH" } },
                                                [_vm._v("REEFER HIGH CUBE")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "TK" } },
                                                [_vm._v("TANK")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "OT" } },
                                                [_vm._v("OPEN TOP")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "FR" } },
                                                [_vm._v("FLAT RACK")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c("input", {
                                            staticClass: "wid80",
                                            attrs: {
                                              type: "text",
                                              id:
                                                "cntrQty_" +
                                                (index + 1) +
                                                "_" +
                                                cntr.cntrSeqRn,
                                              name:
                                                "cntrQty_" +
                                                (index + 1) +
                                                "_" +
                                                cntr.cntrSeqRn,
                                              disabled: "",
                                            },
                                            domProps: { value: cntr.cntrQty },
                                          }),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.objBookingDetailMain
                                                      .polShtmCd,
                                                  expression:
                                                    "objBookingDetailMain.polShtmCd",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "cntrTerm_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                id:
                                                  "cntrTerm_" +
                                                  (index + 1) +
                                                  "_" +
                                                  cntr.cntrSeqRn,
                                                disabled: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.objBookingDetailMain,
                                                    "polShtmCd",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "01" } },
                                                [_vm._v("CY")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "02" } },
                                                [_vm._v("CFS")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input_calendar mid",
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "text",
                                                  id:
                                                    "pickupDateImsi_" +
                                                    (index + 1) +
                                                    "_" +
                                                    cntr.cntrSeqRn,
                                                  name:
                                                    "pickupDateImsi_" +
                                                    (index + 1) +
                                                    "_" +
                                                    cntr.cntrSeqRn,
                                                  placeholder: "YYYY-MM-DD",
                                                  disabled: "",
                                                },
                                                domProps: {
                                                  value: cntr.pcupReqDt,
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "tbl_icon calendar",
                                                },
                                                [_vm._v("달력")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm.objBookingDetailMain.polShtmCd ==
                                        "01"
                                          ? _c("td", [
                                              _c("p", [
                                                _c("input", {
                                                  attrs: {
                                                    type: "text",
                                                    id:
                                                      "pickUpPlace_" +
                                                      (index + 1) +
                                                      "_" +
                                                      cntr.cntrSeqRn,
                                                    name:
                                                      "pickUpPlace_" +
                                                      (index + 1) +
                                                      "_" +
                                                      cntr.cntrSeqRn,
                                                    readonly: "",
                                                  },
                                                  domProps: {
                                                    value: cntr.pcupCyNm,
                                                  },
                                                }),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm.objBookingDetailMain.polShtmCd ==
                                        "01"
                                          ? _c("td", [
                                              _c(
                                                "div",
                                                { staticClass: "tbl_form" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "dv col_3 font_12 text_left",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "msg.CMBK100_M1.023_1"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dv" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              cntr.beforePcupPic,
                                                            expression:
                                                              "cntr.beforePcupPic",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "text",
                                                          id:
                                                            "pickUpMan_" +
                                                            (index + 1) +
                                                            "_" +
                                                            cntr.cntrSeqRn,
                                                          name:
                                                            "pickUpMan_" +
                                                            (index + 1) +
                                                            "_" +
                                                            cntr.cntrSeqRn,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            cntr.beforePcupPic,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              cntr,
                                                              "beforePcupPic",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "tbl_form mt5" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "dv col_3 font_12 text_left",
                                                    },
                                                    [_vm._v(" Tel ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "dv" },
                                                    [
                                                      _c("input", {
                                                        attrs: {
                                                          type: "text",
                                                          id:
                                                            "pickUpTel_" +
                                                            (index + 1) +
                                                            "_" +
                                                            cntr.cntrSeqRn,
                                                          name:
                                                            "pickUpTel_" +
                                                            (index + 1) +
                                                            "_" +
                                                            cntr.cntrSeqRn,
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            cntr.afterPcupPic,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.objBookingDetailMain.polShtmCd ==
                                        "02"
                                          ? _c(
                                              "td",
                                              { attrs: { colspan: "2" } },
                                              [_c("p", [_vm._v("-")])]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                          _vm.combineCntrList.length === 0
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "8" } }, [
                                  _vm._v(_vm._s(_vm.$t("js.common.006"))),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  }),
                ]
              : _vm._e(),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.271"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_col" }, [
              _c("colgroup", [
                _c("col", { staticStyle: { width: "10%" } }),
                _c("col", { staticStyle: { width: "18%" } }),
                _c("col", { staticStyle: { width: "10%" } }),
                _c("col", { staticStyle: { width: "10%" } }),
                _c("col", { staticStyle: { width: "8%" } }),
                _c("col", { staticStyle: { width: "20%" } }),
                _vm.objBookingDetailMain.polShtmCd === "01"
                  ? _c("col", { staticStyle: { width: "24%" } })
                  : _vm._e(),
              ]),
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.274")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.275")))]),
                    _vm._v(" "),
                    _vm.objBookingDetailMain.polShtmCd === "01"
                      ? [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.276")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.277")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.278")))]),
                        ]
                      : _vm._e(),
                    _vm.objBookingDetailMain.polShtmCd === "02"
                      ? [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.279")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.280")))]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "tbody",
                _vm._l(
                  _vm.objBookingDetailContainer,
                  function (list, objIndex) {
                    return _c("tr", { key: objIndex }, [
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: list.cntrSzCd,
                                expression: "list.cntrSzCd",
                              },
                            ],
                            attrs: {
                              name: "containerSize_" + (objIndex + 1),
                              id: "containerSize_" + (objIndex + 1),
                              disabled: "",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  list,
                                  "cntrSzCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "20" } }, [
                              _vm._v("20"),
                            ]),
                            _c("option", { attrs: { value: "40" } }, [
                              _vm._v("40"),
                            ]),
                            _c("option", { attrs: { value: "45" } }, [
                              _vm._v("45"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: list.cntrTypCd,
                                expression: "list.cntrTypCd",
                              },
                            ],
                            attrs: {
                              name: "containerType_" + (objIndex + 1),
                              id: "containerType_" + (objIndex + 1),
                              disabled: "",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  list,
                                  "cntrTypCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "GP" } }, [
                              _vm._v("Dry"),
                            ]),
                            _c("option", { attrs: { value: "HC" } }, [
                              _vm._v("HIGH CUBE"),
                            ]),
                            _c("option", { attrs: { value: "RF" } }, [
                              _vm._v("REEFER"),
                            ]),
                            _c("option", { attrs: { value: "RH" } }, [
                              _vm._v("REEFER HIGH CUBE"),
                            ]),
                            _c("option", { attrs: { value: "TK" } }, [
                              _vm._v("TANK"),
                            ]),
                            _c("option", { attrs: { value: "OT" } }, [
                              _vm._v("OPEN TOP"),
                            ]),
                            _c("option", { attrs: { value: "FR" } }, [
                              _vm._v("FLAT RACK"),
                            ]),
                            _vm.lang !== "KOR"
                              ? _c("option", { attrs: { value: "SR" } }, [
                                  _vm._v("SUPER RACK"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c("input", {
                          attrs: {
                            type: "text",
                            id: "containerQty_" + (objIndex + 1),
                            name: "containerQty_" + (objIndex + 1),
                            disabled: "",
                          },
                          domProps: { value: list.cntrQty },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.objBookingDetailMain.polShtmCd,
                                expression: "objBookingDetailMain.polShtmCd",
                              },
                            ],
                            attrs: {
                              name: "containerPolTerm_" + (objIndex + 1),
                              id: "containerPolTerm_" + (objIndex + 1),
                              disabled: "",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.objBookingDetailMain,
                                  "polShtmCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "01" } }, [
                              _vm._v("CY"),
                            ]),
                            _c("option", { attrs: { value: "02" } }, [
                              _vm._v("CFS"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "input_calendar mid" },
                          [
                            _c("e-date-picker", {
                              attrs: {
                                id: "pickUpDate_" + (objIndex + 1),
                                name: "pickUpDate_" + (objIndex + 1),
                                disabled: _vm.editView == "Y",
                              },
                              model: {
                                value: list.newPcupReqDt,
                                callback: function ($$v) {
                                  _vm.$set(list, "newPcupReqDt", $$v)
                                },
                                expression: "list.newPcupReqDt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.objBookingDetailMain.polShtmCd == "01"
                        ? _c(
                            "td",
                            { attrs: { id: "pickUpTable_" + (objIndex + 1) } },
                            [
                              _c("p", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: list.newPcupCyNm,
                                      expression: "list.newPcupCyNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    id: "pickUpPlace_" + (objIndex + 1),
                                    name: "pickUpPlace_" + (objIndex + 1),
                                    readonly: _vm.selectedOwtrYn === "N",
                                  },
                                  domProps: { value: list.newPcupCyNm },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.editView != "Y"
                                        ? _vm.fnPickUpPlace(objIndex)
                                        : ""
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        list,
                                        "newPcupCyNm",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.objBookingDetailMain.polShtmCd == "02"
                        ? _c(
                            "td",
                            {
                              attrs: {
                                id: "pickUpTable_" + (objIndex + 1),
                                colspan:
                                  _vm.objBookingDetailMain.polShtmCd == "02"
                                    ? 2
                                    : 1,
                              },
                            },
                            [
                              objIndex === 0
                                ? [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.objBookingDetailMain
                                                .cfsFixdTypCd,
                                            expression:
                                              "objBookingDetailMain.cfsFixdTypCd",
                                          },
                                        ],
                                        attrs: {
                                          name: "cfsFixdTypCd" + (objIndex + 1),
                                          id: "cfsFixdTypCd_" + (objIndex + 1),
                                          disabled: _vm.editView == "Y",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.objBookingDetailMain,
                                              "cfsFixdTypCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "01" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("msg.CSBK100.047"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "02" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("msg.CSBK100.048"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "03" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("msg.CSBK100.283"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.objBookingDetailMain
                                              .cfsFixdEntrNm,
                                          expression:
                                            "objBookingDetailMain.cfsFixdEntrNm",
                                        },
                                      ],
                                      attrs: {
                                        type: "text",
                                        name: "cfsFixdEntrNm_" + (objIndex + 1),
                                        id: "cfsFixdEntrNm_" + (objIndex + 1),
                                        disabled: _vm.editView == "Y",
                                      },
                                      domProps: {
                                        value:
                                          _vm.objBookingDetailMain
                                            .cfsFixdEntrNm,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.objBookingDetailMain,
                                            "cfsFixdEntrNm",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : [_c("p", [_vm._v("-")])],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.objBookingDetailMain.polShtmCd == "01"
                        ? _c("td", [
                            _c("div", { staticClass: "tbl_form" }, [
                              _c(
                                "span",
                                { staticClass: "dv col_3 font_12 text_left" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("msg.CMBK100_M1.023_1")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "dv" }, [
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    name: "pickUpMan_" + (objIndex + 1),
                                    id: "pickUpMan_" + (objIndex + 1),
                                    readonly: "",
                                  },
                                  domProps: { value: list.newBeforePcupPic },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "tbl_form mt5" }, [
                              _c(
                                "span",
                                { staticClass: "dv col_3 font_12 text_left" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("msg.CSBK100.285")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "dv" }, [
                                _c("input", {
                                  attrs: {
                                    type: "text",
                                    name: "pickUpTel_" + (objIndex + 1),
                                    id: "pickUpTel_" + (objIndex + 1),
                                    readonly: "",
                                  },
                                  domProps: { value: list.newAfterPcupPic },
                                }),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }
                ),
                0
              ),
            ]),
            (_vm.objBookingDetailMain.polShtmCd != "02" ||
              _vm.cstCatCd == "Y") &&
            _vm.lang == "KOR"
              ? [
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.042"))),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "tbl_col" }, [
                    _vm._m(2),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.042"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.042"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.327"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.067"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text_center" }, [
                          _vm._v(
                            _vm._s(_vm.$t("msg.CSBK100.046")) +
                              "/" +
                              _vm._s(_vm.$t("msg.CSDO700_M1.007")) +
                              "/email"
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedOwtrYn,
                                  expression: "selectedOwtrYn",
                                },
                              ],
                              attrs: {
                                name: "cboTransType",
                                id: "cboTransType",
                                disabled: _vm.editView == "Y",
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedOwtrYn = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.fnChangeTransType()
                                  },
                                ],
                                click: function ($event) {
                                  return _vm.fnChangeType(
                                    $event,
                                    "cboTransType"
                                  )
                                },
                              },
                            },
                            [
                              _vm.lang == "KOR" && _vm.cstCatCd != "Y"
                                ? _c(
                                    "option",
                                    {
                                      attrs: { value: "N" },
                                      domProps: {
                                        selected:
                                          _vm.objBookingDetailMain.owtrYn ==
                                          "N",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("msg.CSBK100.047")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  attrs: { value: "Y" },
                                  domProps: {
                                    selected:
                                      _vm.objBookingDetailMain.owtrYn == "Y",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.048")))]
                              ),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "search_btn text_left" }, [
                            _vm.lang == "KOR" && _vm.cstCatCd != "Y"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.objBookingDetailMain.tfcoCstNm,
                                      expression:
                                        "objBookingDetailMain.tfcoCstNm",
                                    },
                                  ],
                                  staticClass: "inp_responsive_space7",
                                  attrs: {
                                    type: "text",
                                    name: "txtTransCompany",
                                    id: "txtTransCompany",
                                    readOnly: "",
                                  },
                                  domProps: {
                                    value: _vm.objBookingDetailMain.tfcoCstNm,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.objBookingDetailMain,
                                        "tfcoCstNm",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.lang == "KOR" &&
                            _vm.cstCatCd != "Y" &&
                            _vm.selectedOwtrYn == "Y"
                              ? _c("a", {
                                  staticClass: "search_btn01 ml2",
                                  attrs: {
                                    href: "#",
                                    id: "searchCompany",
                                    name: "searchCompany",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.transCompanySearch.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _c("td", [
                          _vm.lang == "KOR" &&
                          _vm.selectedOwtrYn == "N" &&
                          _vm.cstCatCd == "N"
                            ? _c("div", { attrs: { id: "divFactory" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.zipRegion,
                                        expression: "zipRegion",
                                      },
                                    ],
                                    staticClass: "inp_responsive_space1",
                                    attrs: {
                                      name: "zip1",
                                      id: "zip1",
                                      disabled: _vm.editView == "Y",
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.zipRegion = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {
                                          $event.preventDefault()
                                          return _vm.fnChangeZip1()
                                        },
                                      ],
                                      click: function ($event) {
                                        return _vm.fnChangeType($event, "zip1")
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }),
                                    _vm._l(
                                      _vm.lineZip1,
                                      function (lineZip1, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: {
                                              value: lineZip1.region1,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(lineZip1.region1) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.zipRegion2,
                                      expression: "zipRegion2",
                                    },
                                  ],
                                  staticClass: "inp_responsive_space1",
                                  attrs: {
                                    type: "text",
                                    name: "zip2",
                                    id: "zip2",
                                    maxlength: "30",
                                  },
                                  domProps: { value: _vm.zipRegion2 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnChangeType($event, "zip2")
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.zipRegion2 = $event.target.value
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _vm.lang == "KOR" &&
                          _vm.selectedOwtrYn != "Y" &&
                          _vm.cstCatCd != "Y"
                            ? _c("div", { attrs: { id: "divFactorySet" } }, [
                                _c(
                                  "select",
                                  {
                                    staticClass: "inp_responsive_space1",
                                    attrs: {
                                      name: "cboFactory",
                                      id: "cboFactory",
                                      disabled: _vm.editView == "Y",
                                    },
                                    on: {
                                      change: function ($event) {
                                        $event.preventDefault()
                                        return _vm.fnChangeFactory($event)
                                      },
                                      click: function ($event) {
                                        return _vm.fnChangeType(
                                          $event,
                                          "cboFactory"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.050"))),
                                    ]),
                                    _vm._v(" "),
                                    _vm.objFactoryList
                                      ? _vm._l(
                                          _vm.objFactoryList,
                                          function (list, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value:
                                                    list.facPicNm +
                                                    "/" +
                                                    list.facCtfcNo +
                                                    "/" +
                                                    list.facNm,
                                                  selected:
                                                    _vm.objBookingDetailMain
                                                      .facNm == list.facNm,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(list.facNm) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm.lang == "KOR" || _vm.selectedOwtrYn == "N"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.objBookingDetailMain.facNm,
                                          expression:
                                            "objBookingDetailMain.facNm",
                                        },
                                      ],
                                      staticClass: "inp_responsive_space1",
                                      attrs: {
                                        type: "text",
                                        name: "txtFactory",
                                        id: "txtFactory",
                                      },
                                      domProps: {
                                        value: _vm.objBookingDetailMain.facNm,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fnChangeType(
                                            $event,
                                            "cboFactory"
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.objBookingDetailMain,
                                            "facNm",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _vm.lang == "KOR" &&
                          _vm.selectedOwtrYn == "N" &&
                          _vm.cstCatCd == "N"
                            ? _c("div", { attrs: { id: "divMan" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.objBookingDetailMain.facPicNm,
                                      expression:
                                        "objBookingDetailMain.facPicNm",
                                    },
                                  ],
                                  staticClass: "wid80",
                                  attrs: {
                                    type: "text",
                                    name: "txtManName",
                                    id: "txtManName",
                                    placeholder: "담당",
                                    disabled: _vm.editView == "Y",
                                  },
                                  domProps: {
                                    value: _vm.objBookingDetailMain.facPicNm,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.objBookingDetailMain,
                                        "facPicNm",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.objBookingDetailMain.facCtfcNo,
                                      expression:
                                        "objBookingDetailMain.facCtfcNo",
                                    },
                                  ],
                                  staticClass: "wid90",
                                  attrs: {
                                    type: "text",
                                    name: "txtManTel",
                                    id: "txtManTel",
                                    placeholder: "연락처",
                                    disabled: _vm.editView == "Y",
                                  },
                                  domProps: {
                                    value: _vm.objBookingDetailMain.facCtfcNo,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.objBookingDetailMain,
                                        "facCtfcNo",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.objBookingDetailMain.facPicEmlAddr,
                                      expression:
                                        "objBookingDetailMain.facPicEmlAddr",
                                    },
                                  ],
                                  staticClass: "wid80",
                                  attrs: {
                                    type: "text",
                                    name: "facPicEmlAddr",
                                    id: "facPicEmlAddr",
                                    placeholder: "email",
                                    disabled: _vm.editView == "Y",
                                  },
                                  domProps: {
                                    value:
                                      _vm.objBookingDetailMain.facPicEmlAddr,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.objBookingDetailMain,
                                        "facPicEmlAddr",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm.lang == "KOR"
                        ? _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "text_left",
                                attrs: { colspan: "2" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "23px" } },
                                  [_vm._v(_vm._s(_vm.$t("msg.CSBK100.328")))]
                                ),
                              ]
                            ),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("div", { staticClass: "position_relative" }, [
                                _c("div", [
                                  _vm.objBookingDetailMain.podCtrCd == "IN" ||
                                  _vm.objBookingDetailMain.podCtrCd == "PK" ||
                                  _vm.objBookingDetailMain.podCtrCd == "BD" ||
                                  _vm.objBookingDetailMain.podCtrCd == "RU"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.remark,
                                            expression: "remark",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "dorOdrRmk",
                                          name: "dorOdrRmk",
                                          placeholder:
                                            _vm.$t("msg.CSBK100.329"),
                                          readonly: "",
                                        },
                                        domProps: { value: _vm.remark },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.remark = $event.target.value
                                          },
                                        },
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.remark,
                                            expression: "remark",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          id: "dorOdrRmk",
                                          name: "dorOdrRmk",
                                          placeholder:
                                            _vm.$t("msg.CSBK100.329"),
                                          disabled: _vm.editView == "Y",
                                          maxlength: "25",
                                        },
                                        domProps: { value: _vm.remark },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.remark = $event.target.value
                                          },
                                        },
                                      }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "2px",
                                      right: "10px",
                                      width: "100px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color_black font_medium",
                                      },
                                      [_vm._v(_vm._s(_vm.remark.length))]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "color_lightgray" },
                                      [_vm._v("/")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "color_lightgray" },
                                      [_vm._v("25")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.lang == "KOR"
                    ? _c("p", { staticClass: "txt_desc" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.330"))),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _vm.editView != "Y"
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnCombine.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.232")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnDetail.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.320")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg float_right",
              on: {
                click: function ($event) {
                  return _vm.closePopup("Y")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.068")))]
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "24%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="popup_wrap booking_combine_detail" style="width:900px; height:530px;">
    <!-- popup_wrap :  style="width:900px; height:600px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">Booking Combine</h1>

      <div class="content_box mt10">
        <!-- content_box -->

        <div class="flex_box">
          <h2 class="content_title">{{ $t('msg.CSBK100.257') }}</h2>
          <div class="bkbl_no tbl_topright">
            <span class="title">{{ $t('msg.CSBK100.258') }}</span> <!-- Booking No. -->
            <span class="txt_bar2"></span>
            <span>{{ bkgNo }}</span>
          </div>
        </div>

        <table class="tbl_row">
          <colgroup>
            <col width="130"><col><col width="130"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBK100.259') }}</th> <!-- 출발(T/S) -->
              <td>
                <ul class="schedule_info">
                  <li>{{ objBookingDetailMain.porPlcEnm }}</li>
                  <li>
                    <!-- ETD : -->
                    {{ $t('msg.CSBK100.260') }} : {{ etd }}
                  </li>
                </ul>
              </td>
              <th>{{ $t('msg.CSBK100.030') }}</th> <!-- 도착 -->
              <td>
                <ul class="schedule_info">
                  <li>{{ objBookingDetailMain.dlyPlcEnm }}</li>
                  <li>
                    <!-- ETA : -->
                    {{ $t('msg.CSBK100.261') }} : {{ eta }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.262') }}</th> <!-- 선명/항차 -->
              <td>
                {{ objBookingDetailMain ? (objBookingDetailMain.vslNm + '/' + objBookingDetailMain.voyNo) : '' }}
              </td>
              <th>{{ $t('msg.CSBK100.011') }}</th> <!-- 실화주 -->
              <td>
                <input type="text"
                       id="txtRealCompany"
                       name="txtRealCompany"
                       :value="objBookingDetailMain.actShprCstEnm"
                       maxlength="30"
                       readOnly
                />
                <span class="float_right" v-if="lang == 'KOR'">
                  <input type="checkbox" id="consYn" name="consYn"
                         :checked="objBookingDetailMain.consYn == 'Y'"
                         disabled
                  />
                  <label for="consYn"><span></span>{{ $t('msg.CSBK100.263') }}</label> <!-- 콘솔부킹 -->
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.030-1') }}</th> <!-- Term -->
              <td>
                <div v-if="cstCatCd != 'Y'" class="tbl_form">
                  <!-- 일반 화주일 때-->
                  <span class="dv">
                    <select
                      name="cboTerm"
                      id="cboTerm"
                      v-model="objBookingDetailMain.cboTerm"
                      @click.prevent="fnChangeType($event, 'cboTerm')"
                      disabled
                    >
                      <option value="01">CY</option>
                      <option value="02">CFS</option>
                      <option value="03">CY&CFS</option>
                    </select>
                  </span>
                  <span class="dv">
                    <select name="podTerm" id="podTerm" v-model="objBookingDetailMain.podShtmCd" disabled>
                      <option value="01">CY</option>
                      <option value="02">CFS</option>
                    </select>
                  </span>
                </div>

                <div v-else class="tbl_form">
                  <!-- SOC 화주일 때-->
                  <span class="dv">
                    <select name="cboTerm" id="cboTerm" v-model="objBookingDetailMain.cboTerm">
                      <option value="01">CY</option>
                      <option value="02">Tackle</option>
                      <option value="03">FI</option>
                    </select>
                  </span>
                  <span class="dv">
                    <select name="podTerm" id="podTerm" v-model="objBookingDetailMain.podShtmCd">
                      <option value="01">CY</option>
                      <option value="05">Tackle</option>
                      <option value="07">FO</option>
                      <option value="09">T/S Zone</option>
                    </select>
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBK100.027-1') }}</th> <!-- Booking Date -->
              <td>{{ bookingDate }}</td>
            </tr>

            <tr v-if="lang == 'JPN' && fwdrCstCd == '01'">
              <!-- 일본인 경우 -->
              <th>{{ $t('msg.CSBK100.264') }}</th> <!-- Booking Shipper -->
              <td colspan="3">
                <select name="bkgShprCstNo" v-model="objBookingDetailMain.bkgShprCstNo">
                  <option value="">{{ $t('msg.CSBK100.050') }}</option> <!-- 직접입력 -->
                  <option
                    v-for="(item, index) in bkgShprList"
                    :key="index"
                    :selected="item.bkgShprCstNo == objBookingDetailMain.bkgShprCstNo"
                  >
                    {{ item.bkgShprCstEnm }}
                  </option>
                </select>
                &nbsp;&nbsp;&nbsp; <input type="text" class="input txt" style="width:220px;" name="bkgShprCstEnm" :value="objBookingDetailMain.bkgShprCstEnm" maxlength="50" @blur="this.value=this.value.toUpperCase()">
                <br><font color="red">* 弊社と運賃のご契約をされた方を意味します。</font>
              </td>
            </tr>
            <tr v-if="lang == 'JPN'">
              <th>通関業者</th>
              <td colspan="3">
                <select name="fwderSelect" id="fwderSelect" class="col_3" v-model="objBookingDetailMain.fwdrCstNo">
                  <option value="">{{ $t('msg.CSBK100.050') }}</option> <!-- 직접입력 -->
                  <option
                    v-for="(item, index) in fwdrList"
                    :key="index"
                    :selected="fwdrList.fwdrCstNo == objBookingDetailMain.fwdrCstNo"
                  >
                    {{ item.fwdrCstEnm }}
                  </option>
                </select>
                <input type="text" class="input txt inp_space50" style="width:220px;" id="fwdrCstEnm" v-model="objBookingDetailMain.fwdrCstEnm" maxlength="50" @blur="this.value=this.value.toUpperCase()" :readOnly="cstYn">
              </td>
            </tr>

            <tr>
              <th>{{ $t('msg.CSBK100.267') }}</th> <!-- Commodity -->
              <td>
                <input type="text" :value="objBookingDetailMain.cmdtDsc" disabled>
              </td>
              <th>{{ $t('msg.CSBK100.268') }}</th> <!-- Gross Weight -->
              <td>
                <span class="wg"> <!-- ton -->
                  <e-input-number
                    id="combineGrsWtTon"
                    v-model="grsWtTon"
                    :is-comma="true"
                    :input-class="'wid80'"
                    :disabled="isKg"
                    :point="3"
                    maxlength="30"
                    @input="changeGrsWt($event, 'TON')"
                  >
                  </e-input-number> {{ $t('msg.CSBK100.269') }}
                </span>
                <span class="color_gray span_grs"> / </span>
                <span class="wg"> <!-- kg -->
                  <e-input-number
                    id="combineGrsWtKg"
                    v-model="grsWtKg"
                    :is-comma="true"
                    :input-class="'wid80'"
                    :disabled="isTon"
                    maxlength="30"
                    @input="changeGrsWt($event, 'KG')"
                  >
                  </e-input-number> {{ $t('msg.CSBK100.270') }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <template v-if="editView != 'Y'">
          <h2 class="content_title">{{ $t('msg.CSBK100.325') }}</h2> <!-- Combine Information -->
          <table v-for="(mast, index) in combineMasterList" class="tbl_col" :key="index">
            <colgroup>
              <col style="width:6%">
              <col style="width:10%">
              <col style="width:12%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:8%">
              <col style="width:20%">
              <col style="width:24%">
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th colspan="2">{{ $t('msg.CSBK100.258') }}</th> <!-- Booking No.-->
                <th colspan="2">{{ $t('msg.CSBK100.326') }}</th>
                <th colspan="2">{{ $t('msg.CSBK100.268') }}</th> <!-- Gross Weight -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{{ index + 1 }}</td>
                <td colspan="2">
                  {{ mast.bkgNo }}
                </td>
                <td colspan="2">
                  {{ mast.actShprCstEnm }}
                </td>
                <td colspan="2">
                  {{ mast.grsWt }} KG
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th></th>
                <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th>{{ $t('msg.CSBK100.274') }}</th> <!-- Q'ty -->
                <th>{{ $t('msg.CSBK100.275') }}</th> <!-- Term -->
                <template v-if="objBookingDetailMain.polShtmCd == '01'">
                  <th>{{ $t('msg.CSBK100.276') }}</th> <!-- Pick Up 일시 -->
                  <th>{{ $t('msg.CSBK100.277') }}</th> <!-- Pick Up 장소 -->
                  <th>{{ $t('msg.CSBK100.278') }}</th> <!-- Pick Up 담당자 -->
                </template>
                <template v-if="objBookingDetailMain.polShtmCd == '02'">
                  <th>{{ $t('msg.CSBK100.279') }}</th> <!-- 입고일자 -->
                  <th colspan="2">{{ $t('msg.CSBK100.280') }}</th> <!-- 쇼링 업체 (쇼링업체명 기재) -->
                </template>
              </tr>
            </thead>
            <tbody>
              <!-- combine 대상이 될 컨테이너들 -->
              <tr v-for="(cntr, cntrIndex) in combineCntrList" :key="cntrIndex">
                <template v-if="mast.bkgNo == cntr.bkgNo">
                  <td v-if="cntr.cntrSeqRn == 1">
                    <input type="checkbox"
                           :id="'check_' + (index + 1)"
                           :name="'check_' + (index + 1)"
                           @click="combineContainerAttach($event.target.checked, mast.bkgNo, mast.appCnt)"
                    />
                    <label :for="'check_' + (index + 1)"><span></span></label>
                  </td>
                  <td v-else style="border-top:none;">
                  </td>
                  <td>
                    <select :name="'cntrSzCd_' + (index + 1) + '_' + cntr.cntrSeqRn"
                            :id="'cntrSzCd_' + (index + 1) + '_' + cntr.cntrSeqRn"
                            v-model="cntr.cntrSzCd"
                            disabled
                    >
                      <option value="20">20</option>
                      <option value="40">40</option>
                    </select>
                  </td>
                  <td>
                    <select :name="'cntrTypCd_' + (index + 1) + '_' + cntr.cntrSeqRn"
                            :id="'cntrTypCd_' + (index + 1) + '_' + cntr.cntrSeqRn"
                            v-model="cntr.cntrTypCd"
                            disabled
                    >
                      <option value="GP">Dry</option>
                      <option value="HC">HIGH CUBE</option>
                      <option value="RF">REEFER</option>
                      <option value="RH">REEFER HIGH CUBE</option>
                      <option value="TK">TANK</option>
                      <option value="OT">OPEN TOP</option>
                      <option value="FR">FLAT RACK</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" :id="'cntrQty_' + (index + 1) + '_' + cntr.cntrSeqRn" :name="'cntrQty_' + (index + 1) + '_' + cntr.cntrSeqRn" class="wid80" :value="cntr.cntrQty" disabled>
                  </td>
                  <td>
                    <select :name="'cntrTerm_' + (index + 1) + '_' + cntr.cntrSeqRn" :id="'cntrTerm_' + (index + 1) + '_' + cntr.cntrSeqRn" v-model="objBookingDetailMain.polShtmCd" disabled>
                      <option value="01">CY</option>
                      <option value="02">CFS</option>
                    </select>
                  </td>
                  <td>
                    <div class="input_calendar mid">
                      <input type="text" class=""
                             :id="'pickupDateImsi_' + (index + 1) + '_' + cntr.cntrSeqRn"
                             :name="'pickupDateImsi_' + (index + 1) + '_' + cntr.cntrSeqRn"
                             :value="cntr.pcupReqDt"
                             placeholder="YYYY-MM-DD"
                             disabled
                      >
                      <button class="tbl_icon calendar">달력</button>
                    </div>
                  </td>
                  <td v-if="objBookingDetailMain.polShtmCd == '01'">
                    <p>
                      <input type="text"
                             :id="'pickUpPlace_' + (index + 1) + '_' + cntr.cntrSeqRn"
                             :name="'pickUpPlace_' + (index + 1) + '_' + cntr.cntrSeqRn"
                             :value="cntr.pcupCyNm" readonly
                      >
                    </p>
                  </td>
                  <td v-if="objBookingDetailMain.polShtmCd == '01'">
                    <div class="tbl_form">
                      <span class="dv col_3 font_12 text_left">
                        {{ $t('msg.CMBK100_M1.023_1') }}
                      </span>
                      <span class="dv">
                        <input type="text"
                               :id="'pickUpMan_' + (index + 1) + '_' + cntr.cntrSeqRn"
                               :name="'pickUpMan_' + (index + 1) + '_' + cntr.cntrSeqRn"
                               v-model="cntr.beforePcupPic"
                               readonly
                        >
                      </span>
                    </div>
                    <div class="tbl_form mt5">
                      <span class="dv col_3 font_12 text_left">
                        Tel
                      </span>
                      <span class="dv">
                        <input type="text"
                               :id="'pickUpTel_' + (index + 1) + '_' + cntr.cntrSeqRn"
                               :name="'pickUpTel_' + (index + 1) + '_' + cntr.cntrSeqRn"
                               :value="cntr.afterPcupPic"
                               readonly
                        >
                      </span>
                    </div>
                  </td>
                  <td v-if="objBookingDetailMain.polShtmCd == '02'" colspan="2">
                    <p>-</p>
                  </td>
                </template>
              </tr>
              <tr v-if="combineCntrList.length === 0">
                <td colspan="8">{{ $t('js.common.006') }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <h2 class="content_title">{{ $t('msg.CSBK100.271') }}</h2> <!-- Container Information -->
        <table class="tbl_col">
          <colgroup>
            <col style="width:10%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:8%">
            <col style="width:20%">
            <col v-if="objBookingDetailMain.polShtmCd === '01'" style="width:24%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBK100.274') }}</th> <!-- Q'ty -->
              <th>{{ $t('msg.CSBK100.275') }}</th> <!-- Term -->
              <template v-if="objBookingDetailMain.polShtmCd === '01'">
                <th>{{ $t('msg.CSBK100.276') }}</th> <!-- Pick Up 일시 -->
                <th>{{ $t('msg.CSBK100.277') }}</th> <!-- Pick Up 장소 -->
                <th>{{ $t('msg.CSBK100.278') }}</th> <!-- Pick Up 담당자 -->
              </template>
              <template v-if="objBookingDetailMain.polShtmCd === '02'">
                <th>{{ $t('msg.CSBK100.279') }}</th> <!-- 입고일자 -->
                <th>{{ $t('msg.CSBK100.280') }}</th> <!-- 쇼링 업체 (쇼링업체명 기재) -->
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, objIndex) in objBookingDetailContainer" :key="objIndex">
              <td>
                <!-- Size -->
                <select
                  :name="'containerSize_' + (objIndex + 1)"
                  :id="'containerSize_' + (objIndex + 1)"
                  v-model="list.cntrSzCd"
                  disabled
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                </select>
              </td>
              <td>
                <!-- Type -->
                <select :name="'containerType_' + (objIndex + 1)" :id="'containerType_' + (objIndex + 1)" v-model="list.cntrTypCd" disabled>
                  <option value="GP">Dry</option>
                  <option value="HC">HIGH CUBE</option>
                  <option value="RF">REEFER</option>
                  <option value="RH">REEFER HIGH CUBE</option>
                  <option value="TK">TANK</option>
                  <option value="OT">OPEN TOP</option>
                  <option value="FR">FLAT RACK</option>
                  <option v-if="lang !== 'KOR'" value="SR">SUPER RACK</option>
                </select>
              </td>
              <td>
                <!-- Q'ty -->
                <input type="text"
                       :id="'containerQty_' + (objIndex + 1)"
                       :name="'containerQty_' + (objIndex + 1)"
                       :value="list.cntrQty" disabled
                >
              </td>
              <td>
                <!-- term -->
                <select :name="'containerPolTerm_' + (objIndex + 1)" :id="'containerPolTerm_' + (objIndex + 1)" v-model="objBookingDetailMain.polShtmCd" disabled>
                  <option value="01">CY</option>
                  <option value="02">CFS</option>
                </select>
              </td>
              <td>
                <!-- Pick Up 일시 -->
                <div class="input_calendar mid">
                  <e-date-picker
                    v-model="list.newPcupReqDt"
                    :id="'pickUpDate_' + (objIndex + 1)"
                    :name="'pickUpDate_' + (objIndex + 1)"
                    :disabled="editView == 'Y'"
                  >
                  </e-date-picker>
                </div>
              </td>
              <td v-if="objBookingDetailMain.polShtmCd == '01'" :id="'pickUpTable_' + (objIndex + 1)">
                <!-- 선적항 CY일 때-Pick Up 장소 -->
                <p>
                  <input type="text"
                         :id="'pickUpPlace_' + (objIndex + 1)"
                         :name="'pickUpPlace_' + (objIndex + 1)"
                         v-model="list.newPcupCyNm"
                         @click.prevent="editView != 'Y' ? fnPickUpPlace(objIndex) : ''"
                         :readonly="selectedOwtrYn === 'N'"
                  >
                </p>
              </td>
              <td v-if="objBookingDetailMain.polShtmCd == '02'" :id="'pickUpTable_' + (objIndex + 1)"
                  :colspan="objBookingDetailMain.polShtmCd == '02' ? 2 : 1"
              >
                <template v-if="objIndex === 0">
                  <select :name="'cfsFixdTypCd' + (objIndex + 1)"
                          :id="'cfsFixdTypCd_' + (objIndex + 1)"
                          :disabled="editView == 'Y'"
                          v-model="objBookingDetailMain.cfsFixdTypCd"
                  >
                    <option value="01">{{ $t('msg.CSBK100.047') }}</option> <!-- Line -->
                    <option value="02">{{ $t('msg.CSBK100.048') }}</option> <!-- 자가 -->
                    <option value="03">{{ $t('msg.CSBK100.283') }}</option> <!-- 쇼링없음 -->
                  </select>
                  <input type="text" :name="'cfsFixdEntrNm_' + (objIndex + 1)"
                         :id="'cfsFixdEntrNm_' + (objIndex + 1)"
                         v-model="objBookingDetailMain.cfsFixdEntrNm"
                         :disabled="editView == 'Y'"
                  > <!-- 선적항 CFS일 때 -->
                </template>
                <template v-else>
                  <p>-</p>
                </template>
              </td>

              <td v-if="objBookingDetailMain.polShtmCd == '01'">
                <div class="tbl_form">
                  <!-- 담당자 -->
                  <span class="dv col_3 font_12 text_left">
                    {{ $t('msg.CMBK100_M1.023_1') }}
                  </span>
                  <span class="dv">
                    <input type="text"
                           :name="'pickUpMan_' + (objIndex + 1)"
                           :id="'pickUpMan_' + (objIndex + 1)"
                           :value="list.newBeforePcupPic"
                           readonly
                    >
                  </span>
                </div>
                <div class="tbl_form mt5">
                  <!-- Tel -->
                  <span class="dv col_3 font_12 text_left">
                    {{ $t('msg.CSBK100.285') }}
                  </span>
                  <span class="dv">
                    <input type="text"
                           :name="'pickUpTel_' + (objIndex + 1)"
                           :id="'pickUpTel_' + (objIndex + 1)"
                           :value="list.newAfterPcupPic"
                           readonly
                    >
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <template v-if="(objBookingDetailMain.polShtmCd != '02' || cstCatCd == 'Y') && lang == 'KOR'">
          <h2 class="content_title">{{ $t('msg.CSBK100.042') }}</h2> <!-- 운송 -->
          <table class="tbl_col">
            <colgroup>
              <col style="width:10%">
              <col style="width:16%">
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:35%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">{{ $t('msg.CSBK100.042') }}</th> <!-- 운송구분 -->
                <th class="text_center">{{ $t('msg.CSBK100.042') }}</th> <!-- 운송사 -->
                <th class="text_center">{{ $t('msg.CSBK100.327') }}</th> <!-- 공장지역 -->
                <th class="text_center">{{ $t('msg.CSBK100.067') }}</th> <!-- 공장명 -->
                <th class="text_center">{{ $t('msg.CSBK100.046') }}/{{ $t('msg.CSDO700_M1.007') }}/email</th> <!-- 담당/연락처/email-->
              </tr>
              <tr>
                <td>
                  <!-- 운송구분 -->
                  <select
                    name="cboTransType"
                    id="cboTransType"
                    :disabled="editView == 'Y'"
                    v-model="selectedOwtrYn"
                    @change="fnChangeTransType()"
                    @click="fnChangeType($event, 'cboTransType')"
                  >
                    <option v-if="lang == 'KOR' && cstCatCd != 'Y'"
                            value="N" :selected="objBookingDetailMain.owtrYn == 'N'"
                    >
                      {{ $t('msg.CSBK100.047') }}
                    </option> <!-- Line -->
                    <option value="Y" :selected="objBookingDetailMain.owtrYn == 'Y'">{{ $t('msg.CSBK100.048') }}</option> <!-- 자가 -->
                  </select>
                </td>
                <td>
                  <!-- 운송사 -->
                  <div class="search_btn text_left">
                    <input type="text"
                           v-if="lang == 'KOR' && cstCatCd != 'Y'"
                           name="txtTransCompany"
                           id="txtTransCompany"
                           class="inp_responsive_space7"
                           v-model="objBookingDetailMain.tfcoCstNm"
                           readOnly
                    >
                    <a v-if="lang == 'KOR' && cstCatCd != 'Y' && selectedOwtrYn == 'Y'"
                       href="#"
                       class="search_btn01 ml2"
                       id="searchCompany"
                       name="searchCompany"
                       @click.prevent="transCompanySearch"
                    ></a>
                  </div>
                </td>
                <td>
                  <!-- 공장지역 -->
                  <div v-if="lang == 'KOR' && selectedOwtrYn == 'N' && cstCatCd == 'N'" id="divFactory">
                    <select name="zip1"
                            id="zip1"
                            class="inp_responsive_space1"
                            :disabled="editView == 'Y'"
                            v-model="zipRegion"
                            @change.prevent="fnChangeZip1()"
                            @click="fnChangeType($event, 'zip1')"
                    >
                      <option value=""></option>
                      <option v-for="(lineZip1, index) in lineZip1" :key="index"
                              :value="lineZip1.region1"
                      >
                        {{ lineZip1.region1 }}
                      </option>
                    </select>
                    <input type="text"
                           name="zip2"
                           id="zip2"
                           class="inp_responsive_space1"
                           maxlength="30"
                           @click="fnChangeType($event, 'zip2')"
                           v-model="zipRegion2"
                    />
                  </div>
                </td>
                <td>
                  <!-- 공장명-->
                  <div v-if="lang == 'KOR' && selectedOwtrYn != 'Y' && cstCatCd != 'Y'" id="divFactorySet">
                    <select
                      name="cboFactory"
                      id="cboFactory"
                      class="inp_responsive_space1"
                      @change.prevent="fnChangeFactory($event)"
                      @click="fnChangeType($event, 'cboFactory')"
                      :disabled="editView =='Y'"
                    >
                      <option value="">{{ $t('msg.CSBK100.050') }}</option> <!-- 직접입력 -->
                      <template v-if="objFactoryList">
                        <option
                          v-for="(list, index) in objFactoryList"
                          :key="index"
                          :value="list.facPicNm + '/' + list.facCtfcNo + '/' + list.facNm"
                          :selected="objBookingDetailMain.facNm == list.facNm"
                        >
                          {{ list.facNm }}
                        </option>
                      </template>
                    </select>
                    <input v-if="lang == 'KOR' || selectedOwtrYn == 'N'"
                           type="text"
                           name="txtFactory"
                           id="txtFactory"
                           class="inp_responsive_space1"
                           v-model="objBookingDetailMain.facNm"
                           @click="fnChangeType($event, 'cboFactory')"
                    >
                  </div>
                </td>
                <td>
                  <!-- 담당/연락처/email -->
                  <div v-if="lang == 'KOR' && selectedOwtrYn == 'N' && cstCatCd == 'N'" id="divMan">
                    <input type="text"
                           name="txtManName"
                           id="txtManName"
                           class="wid80"
                           placeholder="담당"
                           v-model="objBookingDetailMain.facPicNm"
                           :disabled="editView =='Y'"
                    />
                    <input type="text"
                           name="txtManTel"
                           id="txtManTel"
                           class="wid90"
                           placeholder="연락처"
                           v-model="objBookingDetailMain.facCtfcNo"
                           :disabled="editView =='Y'"
                    />
                    <input type="text"
                           name="facPicEmlAddr"
                           id="facPicEmlAddr"
                           class="wid80"
                           placeholder="email"
                           v-model="objBookingDetailMain.facPicEmlAddr"
                           :disabled="editView =='Y'"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="lang == 'KOR'">
                <th colspan="2" class="text_left">
                  <span style="margin-left:23px;">{{ $t('msg.CSBK100.328') }}</span> <!-- 운송 Remark-->
                </th>

                <td colspan="3">
                  <div class="position_relative">
                    <div>
                      <input type="text"
                             v-if="objBookingDetailMain.podCtrCd == 'IN'
                               || objBookingDetailMain.podCtrCd == 'PK'
                               || objBookingDetailMain.podCtrCd == 'BD'
                               || objBookingDetailMain.podCtrCd == 'RU'"
                             id="dorOdrRmk"
                             name="dorOdrRmk"
                             v-model="remark"
                             :placeholder="$t('msg.CSBK100.329')" readonly
                      ><!--  class="input_error" -->
                      <input type="text"
                             v-else
                             id="dorOdrRmk"
                             name="dorOdrRmk"
                             v-model="remark"
                             :placeholder="$t('msg.CSBK100.329')"
                             :disabled="editView == 'Y'"
                             maxlength="25"
                      ><!--  class="input_error" -->
                    <!--<span class="tooltip_essen_box">
                      <span class="tooltip_essen">필수 정보입니다</span>
                      </span>-->
                    </div>
                    <div style="position:absolute; top:2px; right:10px; width:100px; text-align:right">
                      <span class="color_black font_medium">{{ remark.length }}</span>
                      <span class="color_lightgray">/</span>
                      <span class="color_lightgray">25</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="lang == 'KOR'" class="txt_desc">{{ $t('msg.CSBK100.330') }}</p> <!-- 운송 Remark는 최대 25자까지 입력하실 수 있습니다. 25자를 초과하여 기록하실 사항이 있으면 담당자에 문의 바랍니다. -->
        </template>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a v-if="editView != 'Y'" class="button blue lg" @click.prevent="fnCombine">{{ $t('msg.CSBK100.232') }}</a> <!-- Combine -->
        <a class="button blue lg" @click.prevent="fnDetail">{{ $t('msg.CSBK100.320') }}</a>  <!-- Booking Detail -->
        <a class="button gray lg float_right" @click="closePopup('Y')">{{ $t('msg.ONEX020P130.068') }}</a>
      </div>
    </div><!-- popup_cont -->
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div><!-- popup_wrap // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'
import bookingCombine from '@/api/rest/trans/bookingCombine'
import trans from '@/api/rest/trans/trans'
import commons from '@/api/services/commons'
import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'

let combineCount = 0
let qty = 0 //중량제약 벨리데이션에서 사용될 전역 변수
let specialCargoCount = 0 //특수화물 체크

export default ({
  name: 'BookingCombineDetailPop',
  components: {
    EInputNumber,
    EDatePicker,
    'trans-company-pop': () => import('@/pages/trans/popup/TransCompanyPop'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'pick-up-search-pop': () => import('@/pages/trans/popup/PickUpSearchPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          page: 'LIST'
        }
      }
    }
  },
  data: function () {
    return {
      customComponent: null,
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      serverSecond: '',
      selectFunc: null,
      eta: '',
      etd: '',
      selectedOwtrYn: '', //자가, Line 구분(cboTransType)
      remark: '',
      bkgNo: this.parentInfo.bkgNo,
      grsWtTon: '', //중량관련
      grsWtKg: '', //중량관련
      isKg: false, //중량관련
      isTon: false, //중량관련
      grsWt: '', //중량관련
      wtCvsCd: '', //중량관련
      wtAutoClcYn: '', //중량관련
      lang: '',
      cstCatCd: '',
      fwdrCstCd: '',
      zipRegion: '',
      zipRegion2: '',
      txtBookingDate: '',
      combineMasterList: [],
      combineCntrList: [],
      objBookingDetailMain: {},
      objBookingDetailContainer: [],
      lineZip1: [],
      lineZip2: [],
      objFactoryList: [],
      restriction: [],
      bkgShprList: [], //미사용 추정(JPN 전용)
      fwdrList: [], //미사용 추정(JPN 전용)
      cstYn: false,
      editView: '', //수정여부에 따른 화면 제어용 플래그
      popParams: {} //[팝업용]-하위컴포넌트에 전달할 props
    }
  },
  computed: {
    ...rootComputed,
    bookingDate: function () {
      let result = ''
      if (!this.objBookingDetailMain) {
        return result
      } else {
        let bkgDt = this.objBookingDetailMain.bkgDt
        if (!bkgDt) {
          return result
        }
        result = bkgDt.substring(0, 4) + '-' + bkgDt.substring(4, 6) + '-' + bkgDt.substring(6, 8)

        return result
      }
    }
  },
  created () {
    commons.getServerTime().then(res => {
      this.serverYear = res.data.substring(0, 4)
      this.serverMonth = res.data.substring(4, 6)
      this.serverDay = res.data.substring(6, 8)
      this.serverHour = res.data.substring(8, 10)
      this.serverMin = res.data.substring(10, 12)
      this.serverSecond = res.data.substring(12, 14)
    })
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
      this.cstCatCd = this.auth.cstCatCd
    }
    if (this.memberDetail !== undefined) {
      this.fwdrCstCd = this.memberDetail.fwdrCstCd
    }

    this.getBookingCombineInfo()
  },
  methods: {
    //[func]-부킹 combine 상세 정보 가져오기
    async getBookingCombineInfo () {
      let result = await bookingCombine.getBookingCombineDetail({ bkgNo: this.bkgNo })
      this.combineMasterList = result.data.combineMasterList
      this.combineCntrList = result.data.combineCntrList.map((item) => {
        if (item.pcupPic) {
          let pcupPicArr = item.pcupPic.split(',')
          item.beforePcupPic = pcupPicArr[0]
          item.afterPcupPic = pcupPicArr[1]
        }
        return item
      })

      if (this.combineCntrList.length === 0) {
        const TH = this
        this.$ekmtcCommon.alertCallback(this.$t('msg.CSBK100.388'), () => {
          TH.$emit('close')
        })
      }

      //부킹대상 정보 가공
      this.objBookingDetailMain = result.data.objBookingDetailMain
      this.objBookingDetailMain.cboTerm = this.objBookingDetailMain.polShtmCd
      this.objBookingDetailMain.newCboFactory = this.objBookingDetailMain.facNm
      this.selectedOwtrYn = this.objBookingDetailMain.owtrYn
      // 220530. dorOdrRmk에 값이 없을때 split 오류가 발생해서 값 여부 체크로직 추가
      if (this.$ekmtcCommon.isEmpty(this.objBookingDetailMain.dorOdrRmk)) {
        this.zipRegion = ''
        this.zipRegion2 = ''
        this.remark = ''
      } else {
        let zipArr = this.objBookingDetailMain.dorOdrRmk.split('||') //old : ${fn:split(objBookingDetailMain.dor_odr_rmk,'||')}
        let zipRegionArr = zipArr[0].split(',')
        this.zipRegion = zipRegionArr[0]
        this.zipRegion2 = (zipRegionArr.length > 1 ? zipRegionArr[1] : '')
        this.remark = (zipArr.length > 1 ? zipArr[1] : '')
      }

      //부킹대상컨테이너 정보 가공
      this.objBookingDetailContainer = result.data.objBookingDetailContainer.map((item) => {
        if (item.pcupPic) {
          let pcupPicArr = item.pcupPic.split(',')
          item.newBeforePcupPic = pcupPicArr[0]
          item.newAfterPcupPic = pcupPicArr[1]
        }
        item.newPcupCyNm = item.pcupCyNm
        item.newPcupCyCd = item.pcupCyCd
        item.newPcupReqDt = item.pcupReqDt

        return item
      })

      //운송-공장지역
      this.lineZip1 = result.data.lineZip1
      this.lineZip2 = result.data.lineZip2
      this.objFactoryList = result.data.objFactoryList
      this.restriction = result.data.restriction

      //중량정보 가공
      if (this.$ekmtcCommon.isNotEmpty(this.objBookingDetailMain.grsWt)) {
        const type = this.objBookingDetailMain.wtCvsCd === 'K' ? 'KG' : 'TON'
        if (type === 'TON') {
          this.grsWtTon = this.objBookingDetailMain.grsWt
        } else {
          this.grsWtKg = this.objBookingDetailMain.grsWt
        }

        this.changeGrsWt(this.objBookingDetailMain.grsWt, type)
      }
      //상단 ETA, ETD 정보 가공
      this.etd = this.getMonth('etd')
      this.eta = this.getMonth('eta')

      if (this.lang === 'JPN') {
        await this.initFwdrInfo()
      }
    },
    async initFwdrInfo () {
      await trans.getFwdrInfoList().then(res => {
        const result = res.data

        if (result !== undefined) {
          const fwdrList = result.fwdrList
          const bkgShprList = result.bkgShprList

          this.$set(this, 'fwdrList', fwdrList)
          this.$set(this, 'bkgShprList', bkgShprList)

          fwdrList.forEach(f => {
            if (f.fwdrCstNo === this.objBookingDetailMain.fwdrCstNo) {
              this.cstYn = true
            }
          })
        }
      })
    },
    //[func]-ETD, ETA 날짜값 computed 함수
    getMonth (etFlag) {
      let result = ''
      let et = ''
      let etMonth = ''
      let resultEtFront = ''
      let resultEtEnd = ''

      if (this.objBookingDetailMain.length === 0) {
        return result
      } else {
        if (etFlag === 'etd') {
          et = this.objBookingDetailMain.etd
        } else {
          et = this.objBookingDetailMain.eta
        }

        if (this.$ekmtcCommon.isNotEmpty(et)) {
          //etMonth = this.objBookingDetailMain.etd.substring(4, 6)
          etMonth = et.substring(4, 6)
        }
      }

      if (this.$ekmtcCommon.isEmpty(et)) {
        return
      }

      resultEtFront = et.substring(0, 4)
      resultEtEnd = et.substring(6, 8) + ' ' + et.substring(8, 10) + ':' + et.substring(10, 12)

      let month = ''
      if (this.lang === 'ENG') {
        if (etMonth === '01') {
          month = '.Jan.'
        } else if (etMonth === '02') {
          month = '.Feb.'
        } else if (etMonth === '03') {
          month = '.Mar.'
        } else if (etMonth === '04') {
          month = '.Apr.'
        } else if (etMonth === '05') {
          month = '.May.'
        } else if (etMonth === '06') {
          month = '.Jun.'
        } else if (etMonth === '07') {
          month = '.Jul.'
        } else if (etMonth === '08') {
          month = '.Aug.'
        } else if (etMonth === '09') {
          month = '.Sep.'
        } else if (etMonth === '10') {
          month = '.Oct.'
        } else if (etMonth === '11') {
          month = '.Nov.'
        } else if (etMonth === '12') {
          month = '.Dec.'
        }

        result = resultEtFront + month + resultEtEnd
      } else {
        result = resultEtFront + '.' + et.substring(4, 6) + '.' + et.substring(6, 8) + ' ' + et.substring(8, 10) + ':' + et.substring(10, 12)
      }

      return result
    },
    //[func]-운송구분 벨리데이션
    fnChangeTransType () {
      this.objBookingDetailContainer.map((item) => {
        item.newPcupCyCd = ''
        item.newBeforePcupPic = '' //pickUpMan_
        item.newAfterPcupPic = '' //pickUpTel_

        if (this.selectedOwtrYn === 'N') {
          item.newPcupCyNm = 'KCTC'
        } else {
          item.newPcupCyNm = ''
        }
      })

      if (this.selectedOwtrYn === 'N') {
        this.objBookingDetailMain.tfcoCstNm = 'KCTC'
      }
    },
    //[func]-Combine 대상이 되는 컨테이너를 타켓 컨테이너에 붙이거나 빼기
    combineContainerAttach (checked, bkgNo, appCnt) {
      this.zipRegion = ''

      //체킹된 컨테이너가 붙기 전, Line운송이면 메인컨테이너도 다음과 같이 초기화
      if (this.selectedOwtrYn === 'N') {
        this.objBookingDetailContainer = this.objBookingDetailContainer.map((container) => {
          container.newPcupCyNm = this.selectedOwtrYn === 'N' ? 'KCTC' : combineContainer.pcupCyNm
          container.newBeforePcupPic = this.selectedOwtrYn === 'N' ? '' : combineContainer.beforePcupPic
          container.newAfterPcupPic = this.selectedOwtrYn === 'N' ? '' : combineContainer.afterPcupPic
          container.newPcupCyCd = this.selectedOwtrYn === 'N' ? '' : combineContainer.pcupCyCd
          container.newPcupReqDt = this.selectedOwtrYn === 'N' ? '' : combineContainer.pcupReqDt
          return container
        })
      }

      //체크한 Booking No.에 해당하는 컨테이너 리스트를 갖고 온다.
      let checkedContainerList = this.combineCntrList.filter((combineContainer, index) => {
        combineContainer.newPcupCyNm = this.selectedOwtrYn === 'N' ? 'KCTC' : combineContainer.pcupCyNm
        combineContainer.newBeforePcupPic = this.selectedOwtrYn === 'N' ? '' : combineContainer.beforePcupPic
        combineContainer.newAfterPcupPic = this.selectedOwtrYn === 'N' ? '' : combineContainer.afterPcupPic
        combineContainer.newPcupCyCd = this.selectedOwtrYn === 'N' ? '' : combineContainer.pcupCyCd
        combineContainer.newPcupReqDt = this.selectedOwtrYn === 'N' ? '' : combineContainer.pcupReqDt

        return combineContainer.bkgNo === bkgNo
      })

      //target container에 붙이거나 빼기
      if (checkedContainerList.length !== 0 && checked) {
        //넣을 때 새로 바인딩할 new..변수에 셋팅 후 push

        combineCount += 1

        this.objBookingDetailContainer.push(...checkedContainerList)

        if (appCnt > 0) {
          specialCargoCount += 1
        }
      } else {
        combineCount -= 1
        this.objBookingDetailContainer = this.objBookingDetailContainer.filter((targetContainer, index) => {
          return targetContainer.bkgNo !== bkgNo
        })

        if (appCnt > 0) {
          specialCargoCount -= 1
        }
      }
    },
    //[func]-combine 시작
    async fnValidation () {
      let cboTerm = this.objBookingDetailMain.cboTerm

      if (specialCargoCount > 1) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.328') + '\n' + this.$t('msg.CSBK100.332')) //여러 건의 특수화물 Booking Combine은 불가합니다.\nS/R작성 후 REMARK를 남겨시면 하나의 B/L로 진행 가능합니다.
        return false
      }

      if (combineCount === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.333')) //부킹번호를 체크하세요.
        return false
      }

      //픽업관련 벨리데이션
      if (!this.fnPickUpCheck()) {
        return false
      }

      //중량관련 벨리데이션
      if (!await this.fnGrsWtCheck()) {
        return false
      }

      //공장지역 벨리데이션
      if (!this.zipRegion && cboTerm === '01' && this.selectedOwtrYn === 'N') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.334')) //공장지역을 선택해주세요.
        return false
      }

      //공장명,담당자,연락처 벨리데이션
      if (cboTerm === '01' || cboTerm === '03') {
        if (this.selectedOwtrYn === 'N' && !this.objBookingDetailMain.newCboFactory && !this.objBookingDetailMain.facNm) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.335')) //공장명을 입력하세요.
          return false
        }

        if (this.selectedOwtrYn === 'N' && !this.objBookingDetailMain.facPicNm) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.336')) //담당자를 입력하세요.
          return false
        }

        if (this.selectedOwtrYn === 'N' && !this.objBookingDetailMain.facCtfcNo) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.337')) //연락처를 입력하세요.
          return false
        }
      }
      return true
    },
    async fnCombine () {
      if (await this.fnValidation()) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.290'), useConfirmBtn: true })) { //저장하시겠습니까?
          for (let i = 0; i < this.objBookingDetailContainer.length; i++) {
            this.objBookingDetailContainer[i].polShtmCd = document.getElementById('containerPolTerm_' + (i + 1)).value
          }

          //운송이 LINE일 때, 공장명 또는 공장명 직접입력시 값 분기
          if (this.lang === 'KOR' && this.selectedOwtrYn !== 'Y' && this.cstCatCd !== 'Y') {
            let cboFactory = document.getElementById('cboFactory').value
            if (this.selectedOwtrYn === 'N') {
              if (cboFactory) {
                this.objBookingDetailMain.facNm = cboFactory.split('/')[2]
              }
            }
          }

          const formData = {
            bkgNo: this.bkgNo,
            combBkgNo: this.bkgNo,
            grsWt: this.objBookingDetailMain.grsWt,
            wtCvs: this.objBookingDetailMain.wtCvsCd,
            cfsFixdTypCd: this.objBookingDetailMain.cfsFixdTypCd,
            cfsFixdEntrNm: this.objBookingDetailMain.cfsFixdEntrNm,
            tfcoCstNo: this.selectedOwtrYn === 'N' ? 'TRK001' : this.objBookingDetailMain.tfcoCstNo, //old : HidTransCompanyCode
            tfcoCstNm: this.objBookingDetailMain.tfcoCstNm, //old : txtTransCompany
            owtrYn: this.selectedOwtrYn, //운송-소유여부(Line, 자가)
            facNm: this.selectedOwtrYn === 'N' ? this.objBookingDetailMain.facNm : '', //운송-공장명값
            facPicNm: this.selectedOwtrYn === 'N' ? this.objBookingDetailMain.facPicNm : '', //운송-담당자
            facCtfcNo: this.selectedOwtrYn === 'N' ? this.objBookingDetailMain.facCtfcNo : '', //운송-연락처
            facPicEmlAddr: this.selectedOwtrYn === 'N' ? this.objBookingDetailMain.facPicEmlAddr : '', //운송-이메일
            zip1: this.zipRegion, //운송-공장지역(선택값)
            zip2: this.zipRegion2, //운송-공장지역(입력값)
            dorOdrRmk: this.remark, //운송-운송 REMARK
            containerList: this.objBookingDetailContainer //콤바인 할 마스터 컨테이너들
          }

          const result = await bookingCombine.startBookingCombine(formData)
          const resultCode = result.data.rtnCode
          if (resultCode === 'C0000') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.338')) //정상적으로 반영되었습니다.
            this.editView = 'Y'

            if (this.$route.name === 'progress') {
              this.$emit('selectFunc')
            }
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.339')) //Combine하지 못했습니다. 관리자에게 문의하세요.
          }
        }
      }
    },
    //[func]-픽업관련 벨리데이션
    fnPickUpCheck () {
      qty = 0
      let cboTerm = this.objBookingDetailMain.cboTerm

      //3.???벨리데이션에서 사용
      let containerArr = []
      let pickUpArr = []
      let sizeTemp = []
      let typeTemp = []
      let pickUpTemp = []

      //픽업장소 제약
      for (let i = 0; i < this.objBookingDetailContainer.length; i++) {
        let pickUpDate = this.objBookingDetailContainer[i].newPcupReqDt
        let containerSize = this.objBookingDetailContainer[i].cntrSzCd
        let containerType = this.objBookingDetailContainer[i].cntrTypCd
        let shprVanYn = this.objBookingDetailContainer[i].shprVanYn
        let pickUpCyCd = this.objBookingDetailContainer[i].newPcupCyCd
        let str = ''
        //1.pickUpDate 벨리데이션
        if (cboTerm === '01' || cboTerm === '03') {
          str = this.$t('msg.CSBK100.294') //Pick-Up 일시를 확인하세요
        } else {
          str = this.$t('msg.CSBK100.295') //입고 일자를 확인하세요
        }

        if (!pickUpDate && containerType !== 'TK' && shprVanYn !== 'Y') {
          this.$ekmtcCommon.alertDefault(str)
          document.getElementById('pickUpDate_' + (i + 1)).focus()
          return false
        }

        if (!this.fnPickUpDateCheck(pickUpDate, i)) {
          return false
        }

        //2.pickUpPlaceCode 벨리데이션
        let cboTransType = this.selectedOwtrYn
        if (cboTerm === '01') {
          if (cboTransType === 'Y') {
            if (!pickUpCyCd) {
              if (containerType !== 'TK' && shprVanYn !== 'Y') {
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.340')) //Pick-Up 장소를 선택하세요.
                return false
              }
            }
          }
        }

        //3.???벨리데이션
        if (containerSize) {
          if (cboTerm === '01') {
            sizeTemp = containerSize.trim()
            typeTemp = containerType.trim()
            pickUpTemp = pickUpCyCd.trim()
          }
          qty += Number(this.objBookingDetailContainer[i].cntrQty.trim())
        }

        if (pickUpTemp) {
          containerArr[containerArr.length] = sizeTemp + '_' + typeTemp
          pickUpArr[pickUpArr.length] = pickUpTemp
        }
      }

      for (let i = 0; i < containerArr.length; i++) {
        let tempContainer = containerArr[i] //[20_GP, 20_GP]..
        let tempPick = pickUpArr[i] //KBGK..

        for (let j = 0; j < containerArr.length; j++) {
          if (i !== j) {
            if (tempPick !== pickUpArr[j] && this.selectedOwtrYn === 'Y') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.296')) //단일 상차지만 선택 가능합니다
              return false
            }
          }
        }
      }

      return true
    },
    //[func]-픽업 일자 벨리데이션
    fnPickUpDateCheck (pickUpDate, index) {
      if (!pickUpDate) {
        return true
      }

      let bkgDt = this.objBookingDetailMain.bkgDt
      let bookingDateValue = ''
      let bookingDateArr = []
      if (!bkgDt) {
        bookingDateValue = bkgDt.substring(0, 4) + '-' + bkgDt.substring(4, 6) + '-' + bkgDt.substring(6, 8)
      }
      if (bookingDateValue) {
        bookingDateArr = bookingDateValue.split('-')
      }

      let docuPickUpDate = document.getElementById('pickUpDate_' + (index + 1)).value
      if ((docuPickUpDate.length > 0 && docuPickUpDate.length < 8) || (pickUpDate.length > 0 && pickUpDate.length < 8)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.297')) //날짜형식에 맞지 않습니다.
        document.getElementById('pickUpDate_' + (index + 1)).value = ''
        return false
      }

      //pickupTime 미사용 추정

      let cboTerm = this.objBookingDetailMain.cboTerm
      let pickUpDate2 = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
      let bkgDate = new Date(Number(bookingDateArr[0]), Number(bookingDateArr[1]) - 1, Number(bookingDateArr[2]))
      let today = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
      let today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())

      if (pickUpDate2 < today1) {
        if (cboTerm === '01' || cboTerm === '03') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.298')) //Pick up 일자는 현재일자 이후로 입력해주세요.
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.299')) //입고일자는 현재일자 이후로 입력해주세요.
        }
        document.getElementById('pickUpDate_' + (index + 1)).value = ''
        return false
      }

      /**
      라인 운송의 경우, 입력하고자 하는 픽업일은 부킹일 기준 전날 15시까지 해야함.
        (EX:월요일 14:00 화요일로 픽업일 설정 가능
            월요일 16:00 수요일 부터 픽업일 가능)
      자가 운송의 경우, 픽업일 당일 부킹 가능"
      **/
      let pickUpValue = ''
      if (cboTerm === '01' || cboTerm === '03') {
        let today1 = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
        let today2 = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate()) //오늘날짜
        let today = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() + 1) //내일날짜

        //운송이 'Line'일 때
        if (this.selectedOwtrYn === 'N') {
          let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
          let bkgDate = new Date(Number(bookingDateArr[0]), Number(bookingDateArr[1]) - 1, Number(bookingDateArr[2]) + 1)
          let month = ''
          let day = ''

          month = tempPickUpDate.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }
          day = tempPickUpDate.getDate()
          if (String(day).length === 1) {
            day = '0' + day
          }

          pickUpValue = tempPickUpDate.getFullYear() + '' + month + '' + day

          month = bkgDate.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }

          let bkgValue = bkgDate.getFullYear() + '' + month + '' + day

          if (tempPickUpDate < today) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.300')) //Line 운송의 경우, 당일 Pick up은 불가능합니다.
            document.getElementById('pickUpDate_' + (index + 1)).value = ''
            return false
          }

          //오늘날짜구하기
          month = today.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }
          day = today.getDate()
          if (String(day).length === 1) {
            day = '0' + day
          }
          let toDate = today.getFullYear() + '' + month + '' + day

          if (pickUpValue === toDate) {
            if (Number(today1.getHours()) >= 15) {
              this.$ekmtcCommon.alertDefault(pickUpValue.substring(0, 4) + '년' + pickUpValue.substring(4, 6) + '월' + pickUpValue.substring(6, 8) + '일 배차가 종료되었습니다.\n\n이후 날짜를 입력해주세요.')
              document.getElementById('pickUpDate_' + (index + 1)).value = ''
              return false
            }
          }

        //운송이 '자가'일 때
        } else {
          let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
          //오늘날짜도 가능함.
          if (tempPickUpDate < today2 && pickUpDate.length === 8) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.301')) //픽업일은 현재일자 이후로만 가능합니다.
            document.getElementById('pickUpDate_' + (index + 1)).value = ''
            return false
          }
        }

        /***************************
         * 반입마감일 입항일 체크로직
         ***************************/
        let flag = false
        let cgoDate = ''
        let cgoRest = ''

        if (this.restriction.length > 0) {
          cgoRest = this.restriction[0].cgoRest
        }

        if (this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoRest >= 8) {
          cgoDate = new Date(Number(cgoRest.substring(0, 4)), Number(cgoRest.substring(4, 6)) - 1, Number(cgoRest.substring(6, 8)))
          flag = true
        } else {
          let eta = this.objBookingDetailMain.polEta
          if (eta) {
            cgoDate = new Date(Number(eta.substring(0, 4)), Number(eta.substring(4, 6)) - 1, Number(eta.substring(6, 8)))
            flag = true
          }
        }

        if (!flag) {
          return true
        }

        let month = ''
        let day = ''

        month = cgoDate.getMonth() + 1
        if (String(month).length === 1) {
          month = '0' + month
        }

        day = cgoDate.getDate()
        if (String(day).length === 1) {
          day = '0' + day
        }
        let cgoValue = cgoDate.getFullYear() + '' + month + '' + day

        month = today.getMonth() + 1
        if (String(month).length === 1) {
          month = '0' + month
        }

        day = today.getDate()
        if (String(day).length === 1) {
          day = '0' + day
        }
        let todayValue = today.getFullYear() + '' + month + '' + day

        //라인일때
        if (this.objBookingDetailMain.owtrYn === 'N') {
          if (cgoValue === todayValue) {
            if (Number(today1.getHours()) >= 15) {
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018')) //Pick up일자 설정이 불가능합니다. 담당자에게 문의하세요.
              return false
            }
          }
        //자가일때
        } else {
          if (cgoDate < today2) {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018')) //Pick up일자 설정이 불가능합니다. 담당자에게 문의하세요.
            return false
          }
        }
      } else if (cboTerm === '02') {
        let etaDateString = this.objBookingDetailMain.polEta
        let etaDate = new Date(Number(etaDateString.substring(0, 4)), Number(etaDateString.substring(4, 6)) - 1, Number(etaDateString.substring(6, 8)))
        let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))

        if (etaDate < tempPickUpDate) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.302')) //입고일자는 입항일 당일까지 입력가능합니다.
          document.getElementById('pickUpDate_' + (index + 1)).value = ''
          return false
        }
        return true
      }

      /**
       Pick up 일은 선박의 POL 입항일 당일까지 입력 가능 (우선, 반입마감일이 있으면 반입 마감일까지)
      **/
      if (this.lang === 'KOR') {
        if (pickUpDate) {
          let cgoRest = ''

          if (this.restriction.length > 0) {
            cgoRest = this.restriction[0].cgoRest
          }

          //반입마감일이 없을 때
          if (this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoRest.length >= 8) {
            let cgoDate = new Date(Number(cgoRest.substring(0, 4)), Number(cgoRest.substring(4, 6)) - 1, Number(cgoRest.substring(6, 8)))
            let tempPickupDate2 = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
            if (cgoDate < tempPickupDate2) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.303')) //Pickup 일자는 서류마감일시까지 입력가능합니다.
              document.getElementById('pickUpDate_' + (index + 1)).value = ''
              return false
            }

            let containerType = this.objBookingDetailContainer[index].cntrTypCd

            if (containerType) {
              if (containerType === 'RF' || containerType === 'RH') {
                if (cgoDate <= tempPickupDate2) {
                  this.$ekmtcCommon.alertDefault(containerType + this.$t('msg.CSBK100.190')) //컨테이너는 서류마감일 하루 전까지 입력 가능합니다.
                  document.getElementById('pickUpDate_' + (index + 1)).value = ''
                  return false
                }
              }
            }
          //반입마감일이 있을 때
          } else {
            let eta = this.objBookingDetailMain.polEta
            let etaDate = new Date(Number(eta.substring(0, 4)), Number(eta.substring(4, 6)) - 1, Number(eta.substring(6, 8)))
            let pickupDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
            let cgoDate = ''
            if (this.$ekmtcCommon.isNotEmpty(cgoRest)) {
              cgoDate = new Date(Number(cgoRest.substring(0, 4)), Number(cgoRest.substring(4, 6)) - 1, Number(cgoRest.substring(6, 8)))
            }

            if (this.$ekmtcCommon.isNotEmpty(eta) && etaDate < pickupDate && pickUpDate.length >= 8) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.304')) //Pickup 일자는 입항일 당일까지 입력가능합니다.
              document.getElementById('pickUpDate_' + (index + 1)).value = ''
              return false
            }

            let containerType = this.objBookingDetailContainer[index].cntrTypCd

            if (containerType) {
              if (containerType === 'RF' || containerType === 'RH') {
                if (this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoDate <= tempPickupDate2) {
                  this.$ekmtcCommon.alertDefault(containerType + this.$t('msg.CSBK100.190')) //컨테이너는 서류마감일 하루 전까지 입력 가능합니다.
                  document.getElementById('pickUpDate_' + (index + 1)).value = ''
                  return false
                }
              }
            }
          }
        }
      } else {
        // 해외의 경우 출항일까지 픽업날짜 세팅 가능
        //console.log('pickUpDate>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', pickUpDate)
        if (pickUpDate) {
          let etd = this.objBookingDetailMain.etd
          let etdDate = new Date(Number(etd.substring(0, 4)), Number(etd.substring(4, 6)) - 1, Number(etd.substring(6, 8), Number(etd.substring(8, 10))))
          let pickUp = this.$ekmtcCommon.getStrToDate(pickUpDate)
          //console.log('etdDate>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', etdDate)
          if (this.$ekmtcCommon.isNotEmpty(etd) && etdDate <= pickUp && pickUpDate.length >= 8) {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
            return false
          }
        }
      }
      return true
    },
    //[func]-중량 제약 벨리데이션
    async fnGrsWtCheck () {
      let weight = this.objBookingDetailMain.grsWt

      let average = 0
      if (weight) {
        average = Number(weight) / qty

        if (average > 40000) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.305') + '\n' + this.$t('msg.CSBK100.306') + '\n' + this.$t('msg.CSBK100.307')) //컨테이너 당 평균 중량이 40톤 이상입니다.\nG.Weight 항목을 확인하여 수정해 주시기 바랍니다.\n평균 중량이 40톤 이상인 경우, 업무 담당자에게 연락 주시기 바랍니다.
          return false
        }
        if (average < 10000) {
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.308') + '\n' + this.$t('msg.CSBK100.309'), useConfirmBtn: true })) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 10톤 이하입니다.\n수정없이 진행하시겠습니까?
            return true
          } else {
            return false
          }

          // if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.308') + '\n' + this.$t('msg.CSBK100.309'), useConfirmBtn: true })) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 10톤 이하입니다.\n수정없이 진행하시겠습니까?
          //   return true
          // } else {
          //   return false
          // }
        }
        if (average > 25000) {
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.310') + '\n' + this.$t('msg.CSBK100.309'), useConfirmBtn: true })) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 25톤 이상입니다.\n수정없이 진행하시겠습니까?
            return true
          } else {
            return false
          }

          // if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.310') + '\n' + this.$t('msg.CSBK100.309'), useConfirmBtn: true })) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 25톤 이상입니다.\n수정없이 진행하시겠습니까?
          //   return true
          // } else {
          //   return false
          // }
        }
        this.wtAutoClcYn = 'Y'
      } else {
        this.wtAutoClcYn = 'N'
      }
      return true
    },
    //[func]-공장명 변경 시 담당자 등 변경
    fnChangeFactory (event) {
      let value = event.target.value // list.facPicNm/list.facCtfcNo
      this.objBookingDetailMain.newCboFactory = value
      let facPicNm = value.split('/')[0]
      let facCtfcNo = value.split('/')[1]
      this.objBookingDetailMain.facNm = value.split('/')[2]

      //직접입력 여부에 따른 초기화
      if (value) {
        this.objBookingDetailMain.facPicNm = facPicNm
        this.objBookingDetailMain.facCtfcNo = facCtfcNo
        this.objBookingDetailMain.facNm = ''
        document.querySelector('#txtManName').value = facPicNm
        document.querySelector('#txtManTel').value = facCtfcNo
        document.querySelector('#txtFactory').value = ''
        document.querySelector('#txtManName').readOnly = true
        document.querySelector('#txtManTel').readOnly = true
        document.querySelector('#txtFactory').readOnly = true
      } else {
        this.objBookingDetailMain.facPicNm = ''
        this.objBookingDetailMain.facCtfcNo = ''
        document.querySelector('#txtManName').value = ''
        document.querySelector('#txtManTel').value = ''
        document.querySelector('#txtManName').readOnly = false
        document.querySelector('#txtManTel').readOnly = false
        document.querySelector('#txtFactory').readOnly = false
      }
    },
    //[func]-중량 입력에 따른 실시간 벨리데이션1
    changeGrsWt (value, type) {
      //입력값이 비어있는 경우
      if (this.$ekmtcCommon.isEmpty(value)) {
        //KG, TON 모두 readonly 해제
        this.isKg = false
        this.isTon = false

        this.objBookingDetailMain.grsWt = ''
        this.objBookingDetailMain.wtCvsCd = ''
        this.grsWtKg = ''
        this.grsWtTon = ''

        this.checkValidationWeight()
      } else {
        //입력값이 TON인 경우
        if (type === 'TON') {
          //kg값 변환 후 셋팅
          const ton = this.grsWtTon
          const kg = Number(ton) * 1000
          this.grsWtKg = kg
          this.objBookingDetailMain.grsWt = ton
          this.objBookingDetailMain.wtCvsCd = 'T'
          this.isKg = false
          this.isTon = true
        //입력값이 KG인 경우
        } else {
          const kg = this.grsWtKg
          const ton = (Number(kg) * 1000) / 1000000
          this.grsWtTon = ton

          this.objBookingDetailMain.grsWt = kg
          this.objBookingDetailMain.wtCvsCd = 'K'

          this.isKg = true
          this.isTon = false
        }

        this.checkValidationWeight()
      }
    },
    //[func]-중량 입력에 따른 실시간 벨리데이션2
    checkValidationWeight () {
      let isOk = true

      if (this.lang !== 'KOR' && this.objBookingDetailMain.polCtrCd !== 'MY') {
        return true
      }

      const elemTon = document.querySelector('#combineGrsWtTon')
      const elemKg = document.querySelector('#combineGrsWtKg')
      const valTon = this.grsWtTon
      const valKg = this.grsWtKg

      this.$ekmtcCommon.hideErrorTooltip(elemTon)
      this.$ekmtcCommon.hideErrorTooltip(elemKg)
      if (valTon === '' && valKg === '') {
        this.$ekmtcCommon.showErrorTooltip(elemTon, this.$t('msg.CSBK100.181')) // 중량을 ton 혹은 kg 으로 입력해 주세요.
        isOk = false
      }
      return isOk
    },
    //[func]-운송사 찾기 팝업(자가 운송시 노출)
    transCompanySearch () {
      this.selectFunc = this.transCompanySelect

      this.openPopup('trans-company-pop')
    },
    //[func]-운송사 찾기 관련
    transCompanySelect (obj) {
      this.objBookingDetailMain.tfcoCstNm = obj.tfcoCstNm
      this.objBookingDetailMain.tfcoCstNo = obj.tfcoCstNo
    },
    //[func]-운송사 찾기 팝업 OPEN
    openPopup (compNm) {
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.booking_combine_detail .popup_dim')
    },
    //[func]-운송사 찾기 팝업 CLOSE
    closePopup (flag) {
      this.customComponent = null
      this.selectFunc = null
      if (flag === 'Y') {
        this.$emit('close')
      } else {
        this.$ekmtcCommon.layerClose('.booking_combine_detail .popup_dim')
      }
    },
    //[func]-일자에 따른 콤바인 변경 가능여부 벨리데이션
    fnChangeType (event, type) {
      const types = 'txtManName|txtManTel|cboTerm|zip1|zip2|schedule|cboFactory'

      let today1 = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
      let today = new Date(today1.getYear(), today1.getMonth(), today1.getDate() + 1) //내일 날짜를 구해서 Pickup일시와 비교한다.

      let month = today.getMonth() + 1
      if (String(month).length === 1) {
        month = '0' + month
      }
      let day = today.getDate()
      if (String(day).length === 1) {
        day = '0' + day
      }

      let toDateValue = today.getYear() + '' + month + '' + day

      if (!this.objBookingDetailMain.polTrmlCd) {
        return true
      }

      //처음 Booking할 때 pick up일자와 내일날짜를 비교한다.
      let compareValue = ''
      if (this.objBookingDetailMain.cboTerm === '01' || this.objBookingDetailMain.cboTerm === '03') {
        //운송구분 Line -> 자가 변경 시
        if (this.objBookingDetailMain.owtrYn === 'N') {
          for (let i = 0; i < this.objBookingDetailContainer.length; i++) {
            compareValue = this.objBookingDetailContainer[i].pcupReqDt
            let compareDate = new Date(Number(compareValue.substring(0, 4)), Number(compareValue.substring(4, 6)) - 1, Number(compareValue.substring(6, 8)))

            if (toDateValue === compareValue) {
              if (Number(today1.getHours()) >= 15) {
                if (type === 'cboTransType') {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.311') + '\n\n' + this.$t('msg.CSBK100.312')) //내일 Pick up되는 컨테이너가 있습니다. 15시 이후엔 변경 불가\n\n유선으로 문의하세요.(02-311-6114)
                } else if (types.indexOf(type) > -1) {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('art.CMATK182')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                  if (type === 'cboFactory') {
                    document.getElementById('txtFactory').blur()
                  }
                  return false
                } else {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('art.CMATK182')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                  return false
                }
                return false
              }
            }

            if (compareDate < today1) {
              if (type === 'cboTransType') {
                event.target.blur()
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.313') + '\n\n' + this.$t('msg.CSBK100.312')) //Pick up일시가 지난 컨테이너가 있습니다. 수정불가\n\n유선으로 문의하세요.(02-311-6114)
              } else if (types.indexOf(type) > -1) {
                event.target.blur()
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.314')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                if (type === 'cboFactory') {
                  document.getElementById('txtFactory').blur()
                }
              }
              return false
            }
          }
        }
      }
      return true
    },
    //[func]-Line 지역선택시  Options 변화
    fnChangeZip1 () {
      let cboTerm = this.objBookingDetailMain.cboTerm
      let polPortCd = this.objBookingDetailMain.polPortCd
      let kyungkiTypes = '서울|경기|인천|강원'

      this.objBookingDetailContainer.forEach((item) => {
        let containerSize = item.cntrSzCd
        let containerType = item.cntrTypCd
        let pickUpCyCd = item.newPcupCyCd

        if (cboTerm === '02') {
          return
        }
        let selectCFS = 'N'
        if (cboTerm === '02') {
          selectCFS = 'Y'
        }

        //울산 여부 확인
        let selectUlsan = 'N'
        if (polPortCd === 'USN') {
          selectUlsan = 'Y'
        }

        //Line,자가여부 확인
        let selectSelf = ''
        if (cboTerm === '01' || cboTerm === '03') {
          //LINE
          if (this.selectedOwtrYn === 'N' && selectCFS !== 'Y') {
            selectSelf = 'N'
            item.newPcupCyCd = 'KCTC'
          //자가
          } else {
            selectSelf = 'Y'
          }

          //운송구분이 LINE일 때
          if (selectSelf === 'N') {
            //공장 시가 입력되어 있는 경우
            if (this.zipRegion) {
              //선적지가 부산인 경우
              if (polPortCd === 'PUS' || polPortCd === 'PNC') {
                //공장지역이 경기권인 경우
                if (kyungkiTypes.indexOf(this.zipRegion) > -1) {
                  //일반 컨테이너인 경우
                  if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'KBGK'
                  //특수 컨테이너인 경우
                  } else {
                    item.newPcupCyCd = 'ICT:HBCT:UAM'
                  }
                //공장지역이 경기권이 아닌 경우
                } else {
                   if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'UAM'
                  //특수 컨테이너인 경우
                  } else {
                    item.newPcupCyCd = 'HBCT:UAM'
                  }
                }
                //픽업지가 UAM이고 40/HC인 경우
                if (item.newPcupCyCd === 'UAM') {
                  if ((containerType === 'HC' && containerSize === '40') || containerType === 'GP') {
                    item.newPcupCyCd = 'HBCT'
                  }
                }
              //선적지가 인천인 경우
              } else if (polPortCd === 'INC') {
                if (kyungkiTypes.indexOf(this.zipRegion) > -1) {
                  if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'KINC'
                  } else {
                    item.newPcupCyCd = 'ICT:IKEC'
                  }
                } else {
                  if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'IKEC'
                  } else {
                    item.newPcupCyCd = 'ICT:IKEC'
                  }
                }
              //선적지가 광양인 경우
              } else if (polPortCd === 'KAN') {
                if (kyungkiTypes.indexOf(this.zipRegion) > -1) {
                  if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'KBGK'
                  } else {
                    item.newPcupCyCd = 'KIT'
                  }
                } else {
                  item.newPcupCyCd = 'KIT'
                }
              //선적지가 울산인 경우
              } else {
                if (kyungkiTypes.indexOf(this.zipRegion) > -1) {
                  if (containerType === 'GP' || containerType === 'HC') {
                    item.newPcupCyCd = 'KBGK'
                  } else {
                    item.newPcupCyCd = 'UNCT'
                  }
                } else {
                  item.newPcupCyCd = 'UNCT'
                }
              }

              //Line RF, RH 부킹은 HBCT 로 고정. POL => INC는 ICT로 변경
              //Line OT, FR 부킹은 HBCT, ICT 로 고정
              if (containerType === 'RF' || containerType === 'RH' || containerType === 'OT' || containerType === 'FR') {
                if (polPortCd === 'INC') {
                  item.newPcupCyCd = 'ICT'
                } else {
                  item.newPcupCyCd = 'HBCT'
                }
              }

              let formData = {
                pcupCyCd: item.newPcupCyCd,
                cntrSzCd: item.cntrSzCd,
                cntrTypCd: item.cntrTypCd,
                polPortCd: polPortCd
              }

              //let result = await trans.getPickupOne(formData)
            }
          //운송구분이 자가일 때
          } else {
            item.newPcupCyNm = ''
            item.beforePcupPic = ''
            item.afterPcupPic = ''
            item.newPcupCyCd = ''
          }
        //CFS일 때
        } else {
          item.newPcupCyNm = ''
          item.beforePcupPic = ''
          item.afterPcupPic = ''
          item.newPcupCyCd = ''
        }
      })
    },
    //[func]-팝업에서 선택한 값 셋팅
    pickUpSelect (obj) {
      const cyCd = obj.cyCd
      const containerVo = this.objBookingDetailContainer[obj.selectIdx]

      containerVo.newPcupCyCd = obj.cyCd
      containerVo.newPcupCyNm = obj.cyNm
      containerVo.newBeforePcupPic = obj.picNm
      containerVo.newAfterPcupPic = obj.picTel
    },
    //[func]-운송이 자가일 때
    fnPickUpPlace (index) {
      //운송이 LINE인 경우에는 선택 호출 X
      if (this.selectedOwtrYn === 'N') {
        return
      }

      //objBookingContainer에서 사이즈, 타입, 수량 가져옴
      let combineContainer = this.objBookingDetailContainer[index]
      let containerSize = combineContainer.cntrSzCd
      let containerType = combineContainer.cntrTypCd
      let containerQty = combineContainer.cntrQty
      let containerPickUpDate = combineContainer.newPcupReqDt.trim()

      //objBookingMain에서 선적항, 선적터미널 가져옴
      let combineMain = this.objBookingDetailMain
      let polPortCd = combineMain.polPortCd
      let polTrmlCd = combineMain.polTrmlCd

      if (!polPortCd) {
        polPortCd = 'USN'
      }

      //컨테이너 픽업일자가 없을 때
      if (!containerPickUpDate) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.021')) //Pickup 날짜를 입력해주세요!
        document.getElementById('pickUpDate_' + (index + 1)).select()
        return
      }

      //컨테이너 픽업일자가 형식에 안 맞을 때
      if (containerPickUpDate.length < 8) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.022')) //날짜 자릿수는 8자리입니다.
        return
      }

      this.popParams = {
        cntrSize: containerSize,
        cntrType: containerType,
        polPortCd: polPortCd,
        pickupDate: containerPickUpDate,
        selectRow: index
      }

      this.selectFunc = this.pickUpSelect

      this.openPopup('pick-up-search-pop')
    },
    //[func]-Booking Detail로 이동
    async fnDetail () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.315'), useConfirmBtn: true })) { //부킹 수정 화면으로 이동합니다.
        this.closePopup('Y')
        if (this.parentInfo.page !== 'DETAIL') {
          this.$router.push({ name: 'booking-edit', params: { bkgNo: this.bkgNo } }).catch(() => {})
        } else {
          this.$emit('selectFunc', this.bkgNo)
        }
      }
    }
  }
})
</script>

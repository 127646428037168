import Send from '../../trans.client.js'

export default {
    //Booking combine 신규 화면
    getBookingCombineDetail (formData) {
      return Send({
        url: '/trans/booking-combine/' + formData.bkgNo,
        method: 'get'
      })
    },
    //Insert Booking combine
    startBookingCombine (formData) {
      return Send({
        url: '/trans/booking-combine/',
        method: 'post',
        data: formData
      })
    }

}
